<template lang="pug">
.journey-item
  journey-calendar(
    :month="shortMonth"
    :date="date"
  )
  app-card.journey-item__main
    .journey-item__header
      h2.journey-item__title
        | {{ weekName }}
        | {{ journey.course_day.date | formattedDate }} at {{ cityName }}
      app-button(@click.native="showModal" icon icon-large)
        i.material-icons delete
        confirmation-dialog(
          data-test="journey-remove-modal"
          title="Removing configuration"
          descr="Are you sure you want to remove configuration?"
          v-model="showing"
          @okBtnClicked="remove"
        )
    div
      .journey-item__wrapper
        .journey-info
          app-card.journey-course
            .journey-course__header
              h3.journey-course__title {{ journey.course_day.name }}
              .journey-course__students
                i.material-icons.text-primary account_circle
                span(v-if="journey.course_day.max_students") {{ journey.course_day.max_students }}
                span(v-else) 0
            p.journey-course__notes {{ journey.course_day.notes }}
            .journey-item__spacer
            .journey-transport
              .journey-transport__header
                .journey-transport__cell.journey-transport__cell_meeting-space Meeting space

              .journey-transport__row
                .journey-transport__cell.journey-transport__cell_meeting-space
                  location-info(:meetingSpace="journey.course_day.meeting_space")

          .journey-time
            .journey-time__header
              .journey-time__cell Start time
              .journey-time__cell End time
            .journey-time__row
              .journey-time__cell
                app-combobox(
                  :options="START_DEFAULT_TIME"
                  :value="journey.course_start_time"
                  :error="formErrors.hasError('journey_days', {nested: 'course_start_time', index: index})"
                  :error-messages="formErrors.getError('journey_days', {nested: 'course_start_time', index: index})"
                  placeholder="Set time"
                  @input="$emit('update:course_start_time', $event)"
                )
                  i(slot="icon").material-icons access_time
              .journey-time__cell
                app-combobox(
                  :options="END_DEFAULT_TIME"
                  :value="journey.course_end_time"
                  :error="formErrors.hasError('journey_days', {nested: 'course_end_time', index: index})"
                  :error-messages="formErrors.getError('journey_days', {nested: 'course_end_time', index: index})"
                  placeholder="Set time"
                  @input="$emit('update:course_end_time', $event)"
                )
                  i(slot="icon").material-icons access_time
        app-card(
          :error="formErrors.hasError('journey_days', {nested: 'sections', index: index})"
          :error-messages="formErrors.getError('journey_days', {nested: 'sections', index: index})"
        ).journey-sessions
          app-card-item(v-for="section in journey.course_day.course_day_sections" :key="section.id").journey-session
            journey-section(
              :section="section"
              :selectedSections="journey.sections"
              @update:leader="updateSections"
              @update:selectedSections="$emit('update:selectedSections', {val: $event, index})"
            )
          p.no-sessions(v-if="!journey.course_day.course_day_sections.length") No sessions assigned
</template>

<script>
import { convertToShortMonth } from '@/util'
import {mapActions, mapGetters} from 'vuex'
import { START_DEFAULT_TIME, END_DEFAULT_TIME } from '@/util/const'
import {DOWDutchDDD, formattedDate} from '@/util/filters'
import api from '@/services/api/api-courses'
import CourseService from '@/app/admin/models/courseService'

export default {
  name: 'JourneyItem',

  props: {
    journey: {
      required: true,
      type: Object
    },
    formErrors: {
      required: true,
      type: Object
    },
    index: Number
  },

  data: () => ({
    START_DEFAULT_TIME: START_DEFAULT_TIME,
    END_DEFAULT_TIME: END_DEFAULT_TIME,
    showing: false
  }),

  mounted() {
    this.sortedCourseSections()
    this.loadCourseLeaders({cache: true})
  },

  computed: {
    ...mapGetters({
      cities: 'crmCourseCities/items'
    }),

    cityName() {
      let city = this.cities.find(city => city.ID === this.journey.course_day.city)
      return city.name
    },

    weekName() {
      return DOWDutchDDD(formattedDate(this.journey.course_day.date))
    },

    shortMonth() {
      return convertToShortMonth(this.journey.course_day.date)
    },

    date() {
      return new Date(this.journey.course_day.date).getDate()
    }
  },

  methods: {
    ...mapActions({
      loadCourseLeaders: 'crmCourseLeaders/leadersList'
    }),

    showModal() {
      this.showing = true
    },

    sortedCourseSections() {
      this.journey.course_day.course_day_sections.sort((a, b) => a.session.id - b.session.id)
    },

    async updateSections(newSection) {
      try {
        let data = [...this.journey.course_day.course_day_sections]
        let changedSection = data.findIndex(section => section.id === newSection.id)
        data[changedSection] = newSection
        await new CourseService(api).updateCourseDaySections(this.journey.course_day.id, data)
        this.$emit('update:section', {section: newSection, index: changedSection})
      } catch (e) {
        console.log(e)
      }
    },

    async remove() {
      try {
        this.showing = false
        this.$emit('click:remove')
      } catch (error) {
        console.log(error)
        this.showing = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    }
  },

  components: {
    journeyCalendar: () => import('../JourneyCalendar.vue'),
    appCard: () => import('@/components/global/BaseCard.vue'),
    appCardItem: () => import('@/components/global/BaseCardItem.vue'),
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    appCombobox: () => import('@/components/global/actions/BaseCombobox.vue'),
    locationInfo: () => import('../CourseDayLocationInfo.vue'),
    journeySection: () => import('./JourneySection.vue'),
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue')
  }
}
</script>

<style lang="scss">
.journey-item {
  display: flex;
  margin-top: 16px;
  margin-right: 16px;
  margin-left: 16px;

  &__main {
    padding-bottom: 9px;
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin: 16px 10px 12px 16px;
  }

  &__title {
    color: $title-second;
    font-weight: 400;
    font-size: 16px;
  }

  .calendar-date {
    margin-right: 9px;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
  }

  &__spacer {
    width: 100%;
    height: 1px;
    background-color: $bg-filled;
  }
}

.journey-info {
  margin-right: 9px;
  margin-left: 9px;
  width: 60%;
}

.journey-course {
  border: 1px solid $bg-filled;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    margin-right: 16px;
    margin-left: 16px;
  }

  &__notes {
    margin: 12px 16px 16px;
    color: $title-second;
    font-style: italic;
    font-size: 12px;
  }

  &__title {
    color: $title-second;
    font-weight: 400;
    font-size: 14px;
  }

  &__students {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;

    .material-icons {
      margin-right: 4px;
      font-size: 16px;
    }
  }
}

.journey-transport {
  display: table;
  padding: 15px;
  width: 100%;

  &__header {
    display: table-row;
  }

  &__cell {
    display: table-cell;
    padding-bottom: 5px;
    font-weight: 500;
    font-size: 10px;

    &_meeting-space {
      width: 180px;
      vertical-align: top;
    }

    .transport-item {
      margin-bottom: 3px;
      &.not-have {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span {
          margin-left: 5px;
        }
      }
    }
  }

  &__header &__cell {
    padding-bottom: 12px;
    color: $label-color;
  }

  &__row {
    display: table-row;
  }
}

.journey-sessions {
  margin-right: 9px;
  width: 40%;

  .no-sessions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 20px 10px;
    color: $label-color;
    font-weight: 500;
    font-size: 12px;
  }
}

.journey-session {
  padding: 0;

  &:last-of-type {
    border-bottom: none;
  }
}

.journey-time {
  display: table;
  margin-top: 10px;
  margin-left: 16px;

  &__header,
  &__row {
    display: table-row;
  }

  &__cell {
    display: table-cell;
    padding-bottom: 5px;
    color: $label-color;
    font-weight: 500;
    font-size: 10px;

    input {
      width: 60px;
    }
  }
}

</style>
